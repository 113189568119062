import React from 'react';
import mov from './mov/video-1603553202.mp4';
// import shopicon from './image/cart.svg';
// import corpicon from './image/aboutus.svg';
import './App.css';
// import styled from 'styled-components';
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useHistory, useLocation
} from "react-router-dom";


const KutsulabURL = "http://kutsulab.com/home/";
const tieniURL = "https://kutsulab.shop/";

const App: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <div className="App">
      <div className="video-container">
        <div className="video-wrap" >
          <video id="video" muted autoPlay loop playsInline>
            <source src={mov} type="video/mp4" />
            <p>video要素がサポートされていないブラウザでご覧になっています。</p>
          </video>
        </div>
      </div>

      <a href={tieniURL} >
        <div className="text1">kutsulab</div>
      </a>
    </div >
  );
}

export default App;
